import React from "react";

import opM from "./assets/images/soft.webp";

const HomeImages1 = (props) => {
  return (
    <div className="col d-none d-md-inline-block">
      <div className="home-img">
        <img className="" src={opM} alt="Sofware development company" />
      </div>
    </div>
  );
};

export default HomeImages1;
